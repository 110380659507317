<template>
  <div class="story">
    <full-scroll></full-scroll>
    <div class="
                msg-title
                flex flex-justify-between flex-pack-center
                color_FF
            ">
      <div class="flex flex-pack-center flex-1">
        <img src="@/assets/national_emblem.png" alt="" class="msg-national m_r20" />
        <div class="text_align_last">
          <p class="font-size24 m_b10">广阳镇人大代表之家</p>
          <p class="font-size12">
            GUANG YANG ZHEN REN DA DAI BIAO ZHI JIA
          </p>
        </div>
      </div>
      <div class="msg-title-mid-box font-size32 font-blod flex-1">
        <img src="@/assets/images/tot-top-title.png" alt="" />
        <div class="msg-title-mid-box-title"> 政策法律宣传</div>
      </div>
      <div class="flex-end flex-1 flex" @click="backHome">
        <div class="my_text_center">
          <img src="@/assets/images/backhome-new.png" alt="" class="msg-backhome m_b10" />
          <p>返回首页</p>
        </div>
      </div>
    </div>

    <div class="msg-mid flex  flex-justify-center flex-pack-center">
      <div class="feedback-left" v-if="$route.query.sourceType != 4">
        <div class="
                            font-size28 font-blod
                            feedback-left-tot-title
                            color_FF
                            my_text_center
                            m_b10
                        ">
          政策平台
        </div>
        <div class="font-size24 feedback-left-tot-box">
          <van-collapse v-model="activeNames" accordion @change="changeACtiveName">
            <van-collapse-item :title="item.name" :name="item.id" v-for="(item, id) in leaderTypeList"
              :key="id">
              <ul class="">
                <li :class="isActived == index ? 'actived' : ''"
                  v-for="(nitem, index) in leaderTypeList[id].child" :key="index"
                  @click="goToLeaderMlist(nitem.id, index)">
                  <span></span>{{ nitem.name }}
                </li>
              </ul>
            </van-collapse-item>
          </van-collapse>
        </div>
      </div>
      <div class="notice-box" v-if="collect">
        <div class="notice-box-list" id="notice-box-list">
          <ul>
            <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
              <li class="notice-list flex" v-for="(item, id) in newAdverList" :key="id"
                @click="goToDetail(item)">
                <div class="m_r20" v-if="item.cover != null && item.cover != ''">
                  <img :src="item.cover" alt="" />
                </div>
                <div class="
                                        flex-1
                                        m_r20
                                        flex flex-v flex-justify-center
                                    ">
                  <div class="notice-list-box color_FF">
                    <p class="font-size24 m_b20">
                      {{ item.title }}
                    </p>
                    <p class="font-size22 color_9FB">
                      {{ item.releaseTime }}
                    </p>
                  </div>
                </div>
                <div class="
                                        check-info
                                        flex
                                        flex-justify-center
                                        flex-pack-center
                                    ">

                  <span class="font-size18 color_FF">查看详情</span>
                </div>
              </li>
            </van-list>
          </ul>
        </div>
      </div>

      <div class="notice-box" v-else>
        <div class="notice-box-list" id="notice-box-list">
          <ul>
            <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
              <li class="notice-list flex" v-for="(item, id) in newAdverList" :key="id"
                @click="goToDetail(item)">
                <div class="
                                        flex-1
                                        m_r20
                                        flex flex-v flex-justify-center
                                    ">
                  <div class="notice-list-box color_FF">
                    <p class="font-size24 m_b20">
                      {{ item.title }}
                    </p>
                    <div class="flex flex-pack-center flex-justify-between m_b20">
                      <div class="font-size22 color_9FB flex flex-pack-center">
                        <div class=" m_r20">
                          收集状态：
                        </div>
                        <div class=" flex flex-pack-center flex-justify-center"
                          :class="item.status == 1 ? 'state_1' : item.status == 2 ? 'state_2' : 'state_3'">
                          {{ item.status == 1 ? "未开始" : item.status == 2 ? "进行中" : "已结束" }}
                        </div>
                      </div>
                      <div class="font-size22 color_9FB flex flex-pack-center">
                        <div class=" m_r20">
                          已收集：
                        </div>
                        <div>
                          {{ item.showCollectionNum }}
                        </div>
                      </div>
                    </div>
                    <div class="flex flex-pack-center flex-justify-between">
                      <div class="font-size22 color_9FB flex flex-pack-center">
                        <div class=" m_r20">
                          开始时间:
                        </div>
                        <div>
                          {{ item.startTime }}
                        </div>
                      </div>
                      <div class="font-size22 color_9FB flex flex-pack-center">
                        <div class=" m_r20">
                          结束时间:
                        </div>
                        <div>
                          {{ item.endTime }}
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
                <div class="
                                        check-info
                                        flex
                                        flex-justify-center
                                        flex-pack-center
                                    ">
                  <span class="font-size18 color_FF">查看详情</span>
                </div>
              </li>
            </van-list>
          </ul>
        </div>
      </div>
    </div>

    <div class="
                msg-bottom
                flex flex-justify-between flex-pack-center
                font-size28
            ">
      <div class="my_text_center" @click="goBack">
        <img src="@/assets/images/return-new.png" alt="" class="msg-return m_b10" />
        <p class="font-size24 color_FF">返回上级</p>
      </div>
      <div class="font-blod my_text_center color_FF">
      </div>

      <div class="my_text_center color_FF">
        <p class="font-size24 m_b10 font-blod">{{ todayMtime }}</p>
        <p class="font-size14">{{ todayTime }}</p>
      </div>
    </div>
  </div>
</template>
<script>
import { getNewsList, opinionPaginationList } from "@/request/api.js";
import { dateTimeFormat } from "@/request/until.js";
import fullScroll from "@/components/fullScroll.vue";

export default {
  components: { fullScroll },
  data () {
    return {
      policy: true, //是否为政策法
      type: 0,//收集站状态
      sourceType: "",//传的参数id
      collect: true,
      isActived: 0,
      activeNames: 1,
      leaderTypeList: [
        {
          id: 1,
          name: "政策法律宣传",
          child: [
            {
              id: 1,
              name: "政策法律宣传"
            }
          ]
        },
        {
          id: 2,
          name: "立法信息采集站",
          child: [
            {
              id: 0,
              name: "全部"
            },
            {
              id: 1,
              name: "未开始"
            }, {
              id: 2,
              name: "进行中"
            },
            {
              id: 3,
              name: "已结束"
            }
          ]
        }
      ],
      loading: false,
      finished: false,
      totalPage: null,
      todayMtime: "",
      todayTime: "",
      newAdverList: [],//列表数据
      pageNum: 1,
      pageSize: 6,
      scrollTop: 0
    };
  },
  created () {
    this.getTime();
    this.sourceType = this.$route.query.sourceType;
  },
  activated () {
    document.getElementById("notice-box-list").scrollTop = this.scrollTop; // 设置滚动条位置
  },

  mounted () {
    if (sessionStorage.getItem("hasNew") === "true") {
      this.activeNames = 2;
      this.pageNum = 1;
      this.newAdverList = [];
      this.collect = false;
      this.isActived = parseInt(sessionStorage.getItem("isActived"));
      this.opinionPaginationListData();
      sessionStorage.removeItem("isActived");
      sessionStorage.removeItem("hasNew");
    } else {
      this.getFetch();
    }

    this.timer = setInterval(() => {
      this.getTime();
    }, 1000);
  },
  methods: {
    /**
     * 立法意见收集站
     */
    opinionPaginationListData () {
      opinionPaginationList({
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        status: this.type
      }).then((res) => {
        this.loading = false;
        if (res.code == 200) {
          let data = res.data.list;
          if (data.length == 0) {
            this.finished = true;
          } else {
            if (this.pageNum == res.data.totalPage) {
              this.finished = true;
            } else {
              this.finished = false;
            }

            data.forEach((item) => {
              item.createTime = item.createTime.split(" ")[0];
              item.startTime = item.startTime.split(" ")[0];
              item.endTime = item.endTime.split(" ")[0];
            });
            this.totalPage = res.data.totalPage;
            this.newAdverList.push(...data);
          }
        }
      });
    },
    getTime () {
      let _this = this;
      let time = "";
      time = dateTimeFormat(new Date());
      _this.todayTime = time.split(" ")[0];
      _this.todayMtime = time.split(" ")[1].slice(0, 5);
    },
    goToLeaderMlist (id, index) {
      this.pageNum = 1;
      this.type = id;
      this.isActived = index;
      this.newAdverList = [];
      if (id == 1 && index == 0) {
        this.getFetch();
      } else {
        this.opinionPaginationListData();
      }
    },
    changeACtiveName (data) {
      this.pageNum = 1;
      if (data === "" || data === 1) {
        this.isActived = null;
        this.type = data;
        this.collect = true;
        this.newAdverList = [];
        this.policy = true;
        this.getFetch();
      } else {
        this.type = 0;
        this.newAdverList = [];
        this.collect = data === this.leaderTypeList[0].id;
        this.policy = false;
        this.opinionPaginationListData();
      }
      this.isActived = 0;
      this.onLoad();
    },

    /**
     * 政策列表
     */
    getFetch () {
      getNewsList({
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        tid: 10,
        status: 1
      }).then((res) => {
        this.loading = false;
        if (res.code == 200) {
          let data = res.data.list;
          if (data.length == 0) {
            this.finished = true;
          } else {
            if (this.pageNum == res.data.totalPage) {
              this.finished = true;
            } else {
              this.finished = false;
            }

            data.forEach((item) => {
              item.createTime = item.createTime.split(" ")[0];
              item.releaseTime = item.releaseTime.split(" ")[0];
            });
            this.totalPage = res.data.totalPage;
            this.newAdverList.push(...data);
          }
        }
      });

    },

    onLoad () {
      if (this.totalPage <= this.pageNum) {
        this.loading = false;
        this.finished = true;
      } else if (this.policy) {
        this.loading = true;
        this.pageNum++;
        this.getFetch();
      } else {
        this.loading = true;
        this.pageNum++;
        this.opinionPaginationListData();
      }
    },

    goToDetail (data) {
      if (this.collect) {
        this.scrollTop =
          document.getElementById("notice-box-list").scrollTop;
        let Data = JSON.stringify(data);
        this.$router.push({
          name: "supervisedetail",
          params: { data: encodeURIComponent(Data), type: 5 }
        });
      } else {
        this.scrollTop =
          document.getElementById("notice-box-list").scrollTop;
        let Data = JSON.stringify(data);
        sessionStorage.setItem("superviseDetail", Data);
        sessionStorage.setItem("tmpdata", 7);
        sessionStorage.setItem("isActived", this.isActived);
        this.$router.push({
          name: "supervisedetails",
          params: { data: encodeURIComponent(Data), type: 7 }
        });
      }
    },

    //返回首页
    backHome () {
      this.$router.push("/");
    },
    goBack () {
      this.$router.back();
    }
  },

  /**
   * 缓存的页面
   */
  beforeRouteLeave (to, from, next) {
    if (to.path === "/supervisedetails" || to.path === "/supervisedetail") {
      from.meta.keepAlive = true;
    } else {
      from.meta.keepAlive = false;
    }
    next();
  }
};
</script>
